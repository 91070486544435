<template>
  <!-- eslint-disable -->
  <svg viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.885 32.682a.542.542 0 01-.142-1.065 5.32 5.32 0 003.921-5.238v-.011a5.411 5.411 0 00-2.925-4.83l-.317-.165.028-.357c0-.049.009-.1.015-.142a1.27 1.27 0 00.013-.147 4.36 4.36 0 00-4.242-4.437c-.48.008-.954.104-1.4.283l-.427.171-.238-.394a6.946 6.946 0 00-5.906-3.379.541.541 0 11.011-1.084 8.017 8.017 0 016.585 3.531 5.028 5.028 0 011.369-.211h.02a5.444 5.444 0 015.31 5.531v.02a6.49 6.49 0 013.189 5.609 6.4 6.4 0 01-4.72 6.3.54.54 0 01-.144.015z" fill="#000"/><path d="M16.289 32.376a.54.54 0 01-.2-.039 7.744 7.744 0 01-4.834-7.3 7.876 7.876 0 014.137-6.951 8.223 8.223 0 017.87-6.2h.009a.541.541 0 11.008 1.084 7.145 7.145 0 00-6.89 5.6l-.056.253-.233.115a6.784 6.784 0 00-3.762 6.1v.015a6.655 6.655 0 004.154 6.278.542.542 0 01-.2 1.045h-.003zM25.499 40.168a1.627 1.627 0 01-1.155-.478l-6.158-6.158a1.702 1.702 0 112.407-2.407l2.979 2.979V20.86a1.927 1.927 0 113.854 0v13.243l2.98-2.98a1.704 1.704 0 012.776.553 1.7 1.7 0 01-.369 1.855l-6.158 6.159a1.63 1.63 0 01-1.156.477zm-6.11-8.617a.776.776 0 00-.55 1.327l6.158 6.158a.711.711 0 001 0l6.158-6.158a.778.778 0 00-1.1-1.1l-3.768 3.768a.462.462 0 01-.789-.327V20.86a1.003 1.003 0 00-2.006 0v14.358a.462.462 0 01-.789.327l-3.768-3.768a.775.775 0 00-.545-.227h-.001z" fill="#000"/></svg>
</template>

<script>
  export default {
    name : 'MeepIconActionExport'
  };
</script>
